import React, { useState } from "react"
import DisplayHold from "./helpers/display_hold"

import calcStyles from './hold_calculator.module.scss'

const HoldCalc = () => {

    // set initial states //
    const [hold, setHold] = useState(0.0476)
    const [holdVisible, setHoldVisible] = useState(true)
    const [americanOddsOne, setAmericanOddsOne] = useState(-110)
    const [americanOddsTwo, setAmericanOddsTwo] = useState(-110)
    const [alertMsgOne, setAlertMessageOne] = useState("")
    const [alertMsgTwo, setAlertMessageTwo] = useState("")
    const [alertVisibleOne, setAlertVisibleOne] = useState(false)
    const [alertVisibleTwo, setAlertVisibleTwo] = useState(false)


    // update text entries when value is wrong //
    // active field gets raw entry text, all others are wiped //
    const updateWithError = (activeEntry, rawEntry) => {
        console.log(activeEntry)
        // set odds //
        // Odds 1//
        if (activeEntry === 'americanOne') {
            // toggle alerts //
            setAlertVisibleOne(true)
            setAlertVisibleTwo(false)
            // update //
            setAmericanOddsOne(
                rawEntry
            )
        }

        
        // Odds 2//
        if (activeEntry === 'americanTwo') {
            // control viz //
            setAlertVisibleOne(false)
            setAlertVisibleTwo(true)
            // update // 
            setAmericanOddsTwo(
                rawEntry
            )
        }
        
        // set hold //
        setHold('--')
        setHoldVisible(false)

    }

    // update logic if correctly formatted item was provided //
    // visibility and calcs controlls are contained to these update funcs //
    const updateWithValue = (activeEntry, rawEntry) => {
        
        var pctOne = ''
        var pctTwo = ''
        
        if (activeEntry === 'americanOne') {
            // always set entry when dealing with controlled state //
            setAmericanOddsOne(rawEntry)

            // since update is valid, remove error //
            setAlertMessageOne('')

            // if americanTwo still has error we can't update //
            if (americanOddsTwo === '' || alertMsgTwo !== '') {
                setAlertVisibleOne(false)
                setAlertVisibleTwo(true)
            } else {
                
                // clear warnings //
                setAlertVisibleOne(false)
                setAlertVisibleTwo(false)

                // define win prob //
                const parsedEntry = parseFloat(rawEntry)
                const parsedSecondary = parseFloat(americanOddsTwo) 

                if (parsedEntry < 0) {
                    pctOne = (
                        (-1 * parsedEntry) / (-1 * parsedEntry + 100)
                    )
                } else {
                    pctOne = (
                        (100 / (parseFloat(parsedEntry) + 100))
                    )
                }

                if (parsedSecondary < 0) {
                    pctTwo = (
                        (-1 * parsedSecondary) / (-1 * parsedSecondary + 100)
                    )
                } else {
                    pctTwo = (
                        (100 / (parseFloat(parsedSecondary) + 100))
                    )
                }

                // set hold //
                setHold (
                    (pctOne + pctTwo) - 1
                )
                setHoldVisible(true)
            }
        }

        if (activeEntry === 'americanTwo') {
            // always set entry when dealing with controlled state //
            setAmericanOddsTwo(rawEntry)

            // since update is valid, remove error //
            setAlertMessageTwo('')

            // if americanOne still has error we can't update //
            if (americanOddsOne === '' || alertMsgOne !== '') {
                setAlertVisibleOne(true)
                setAlertVisibleTwo(false)
            } else {
                // clear warnings //
                setAlertVisibleOne(false)
                setAlertVisibleTwo(false)
                
                // define win prob //
                const parsedEntry = parseFloat(rawEntry)
                const parsedSecondary = parseFloat(americanOddsOne) 

                if (parsedEntry < 0) {
                    pctOne = (
                        (-1 * parsedEntry) / (-1 * parsedEntry + 100)
                    )
                } else {
                    pctOne = (
                        (100 / (parseFloat(parsedEntry) + 100))
                    )
                }

                if (parsedSecondary < 0) {
                    pctTwo = (
                        (-1 * parsedSecondary) / (-1 * parsedSecondary + 100)
                    )
                } else {
                    pctTwo = (
                        (100 / (parseFloat(parsedSecondary) + 100))
                    )
                }

                // set hold //
                setHold (
                    (pctOne + pctTwo) - 1
                )
                setHoldVisible(true)
            }
        }

    }

    // handle the actual field update //

    // one //
    const updateAmericanOne = event => {

        const odds = event.target.value
        const americanFormatted = parseFloat(odds).toFixed(1)
        
        if (isNaN(americanFormatted) || (americanFormatted < 100 & americanFormatted >-100)) {
            // alert msg if odds not valid //
            setAlertMessageOne('American Odds must be greater than 100 or less than -100')
            updateWithError('americanOne', odds)
        } else {
            // update 
            updateWithValue('americanOne', odds)
        }

    }

    // two //
    const updateAmericanTwo = event => {

        const odds = event.target.value
        const americanFormatted = parseFloat(odds).toFixed(1)
        
        if (isNaN(americanFormatted) || (americanFormatted < 100 & americanFormatted >-100)) {
            // alert msg if odds not valid //
            setAlertMessageTwo('American Odds must be greater than 100 or less than -100')
            updateWithError('americanTwo', odds)
        } else {
            // update 
            updateWithValue('americanTwo', odds)
        }

    }

    
    return (

        <div className={calcStyles.calc_wrapper}>
            <div className={calcStyles.hold_container}>
                <div className={calcStyles.hold_panel}>
                    <div className={calcStyles.check_container}>
                        <h2 className={calcStyles.title}>
                            First Side Odds
                        </h2>
                        <h3 className={calcStyles.subtitle}>
                            Enter the price of the 1st side 
                        </h3>
                        <div className={calcStyles.check_input_container}>
                            <div className={calcStyles.check_div} data-active={alertVisibleOne}>
                                {(alertVisibleOne===false) ? '$' : '\u2297' }
                            </div>
                            <input className={calcStyles.input_value}
                                id={'americanOne'}
                                name={'americanOne'}
                                type="text"
                                value={americanOddsOne}
                                onChange={updateAmericanOne}
                            />
                        </div>
                    </div>
                    <div className={calcStyles.check_container}>
                        <h2 className={calcStyles.title}>
                            Second Side Odds
                        </h2>
                        <h3 className={calcStyles.subtitle}>
                            Enter the price of the 2nd side
                        </h3>
                        <div className={calcStyles.check_input_container}>
                            <div className={calcStyles.check_div} data-active={alertVisibleTwo}>
                                {(alertVisibleTwo===false) ? '$' : '\u2297' }
                            </div>
                            <input className={calcStyles.input_value}
                                id={'americanTwo'}
                                name={'americanTwo'}
                                type="text"
                                value={americanOddsTwo}
                                onChange={updateAmericanTwo}
                            />
                        </div>
                    </div>
                </div>
                <div className={calcStyles.hold_panel}>
                    <div className={calcStyles.hold_right_panel_wrapper}>
                        <div className={calcStyles.hold_right_panel}>
                            <DisplayHold
                                hold={hold}
                                holdVisible={holdVisible}
                            />
                        </div>
                    </div>
                </div>
                <div className={calcStyles.alert_container} data-active={alertVisibleOne}>
                        <p className={calcStyles.alert} data-active={alertVisibleOne}>
                            {alertMsgOne}
                        </p>
                    </div>
                    <div className={calcStyles.alert_container} data-active={alertVisibleTwo}>
                        <p className={calcStyles.alert} data-active={alertVisibleTwo}>
                            {alertMsgTwo}
                        </p>
                    </div>
            </div>
        </div>

    )
}

export default HoldCalc