import React from "react"

import Layout from '../../components/layout'
import Head from '../../components/head'
import calcStyles from './calculators.module.scss'

import HoldCalc from "../../components/Calculators/hold_calculator"
import CalculatorDrop from "../../components/Title Drop/calculator_drop"


const HoldCalculator = () => {

    return (
        <Layout>
            <Head
                title="How to Calculate a Sportsbook Hold"
                pageDescription="A free tool for bettors to determine if the sportsbook's hold is high or low"
            />
                <CalculatorDrop
                    startingItem={'NFL Hold Calculator'}
                />
                <p className={calcStyles.calculator_subtitle}>
                    A free tool for bettors to determine if the sportsbook's hold is high or low
                </p>
                <HoldCalc />
                <div className={calcStyles.content_container}>
                    <h3 className={calcStyles.content_h3}>
                        What is a sportsbook hold?
                    </h3>
                    <p className={calcStyles.content_p}>
                        When a sportsbook sets a line or gives odds, they’re assigning a probability of some event happening. For instance, a bettor wagering $100 on a moneyline of +160 will generate $160 in profit on winning bets and -$100 in losses on losing bets. To break-even, this bettor will have to win at least 38.46% of their bets. We can think of this 38.46% as the approximate likelihood the sportsbook is assigning to the moneyline hitting.<br className={calcStyles.content_break}/>
                        Every line has at least two sides. For instance, our moneyline of +160 on the underdog might be associated with a moneyline of -190 for the favorite. You might think that the odds of the underdog winning or the favorite winning must add up to 100%, right? Well, not quite. If we calculate the implied probability of both these bets, we’d see that the total probability is about 104% (38.46% + 65.51%) . So what’s going on here?<br className={calcStyles.content_break}/>
                        The extra percent above 100% is called the hold, and it represents the percent of dollars wagered that the sportsbook keeps for itself as profit. To break down exactly what that means, you can think of this way -- if the average break-even rate for all bettors adds up to a number over 100%, it’s impossible for all bettors to break-even across their bets. The sportsbook will make a profit by taking more money from losing wagers than they payout to winning wagers.
                    </p>
                    <h3 className={calcStyles.content_h3}>
                        Why is the sportsbook hold important?
                    </h3>
                    <p className={calcStyles.content_p}>
                        The hold is important because it tells bettors how much profit margin the sportsbook will make from a particular line or market. If a sportsbook makes more money on lines with higher holds, then bettors, on average, lose more money on lines with higher holds.<br className={calcStyles.content_break}/>
                        One strategy employed by sports bettors is to look for lines and markets with lower holds as this increases their chances of making money in the long-run. If you want to bet like a professional, you have to know how to calculate the hold
                    </p>
                    <h3 className={calcStyles.content_h3}>
                        How to calculate the sportsbook hold
                    </h3>
                    <p className={calcStyles.content_p}>
                        To calculate the hold, we first need to add up all the implied probabilities. In some markets, like NFL MVP odds, there might be many different probabilities to add up, but we’ll keep it simple here by looking back to our moneyline example.<br className={calcStyles.content_break}/>
                        To calculate the implied odds for an underdog, you simply divide the amount wagered by the total potential payout. So for a +160 underdog, this would be $100 / ($100 + $160), which equals 38.46%.<br className={calcStyles.content_break}/>
                        To calculate the implied odds for a favorite, you divide the odds by $100 plus the odds. So for a -190 favorite, this would be $190 / ($100 + $190), which equals 65.51%.<br className={calcStyles.content_break}/>
                        To calculate the hold, we need to see the difference between the implied odds (ie what the sportsbook gets paid on) and the actual odds (ie what can actually happen). Since the total probability of all possible events always equals 100%, this is just the total of the implied odds minus one. In our example, this would be (38.46% + 65.51%) -1, which equals about 4% of hold for the sportsbook.
                    </p>
                    <h3 className={calcStyles.content_h3}>
                        The sportsbook hold calculator
                    </h3>
                    <p className={calcStyles.content_p}>
                        To make things easier, you can use the free sportsbook hold calculator above. The calculator will calculate the hold for any two odds lines provided. Better yet, it will even tell you how potentially high or low that hold is for you as a bettor. The typical hold for NFL spreads is about 4.76% (-110 on both sides), so if you’re an NFL bettor, you can think of anything above this number being a high hold, and anything below this number as more favorable.
                    </p>
                </div>
        </Layout>
    )

}

export default HoldCalculator