import React, { useState } from "react"

import dropStyles from './title_drop.module.scss'
import TitleDropItem from "./title_drop_item"

const TitleDrop = ({ startingItem, itemList }) => {

    const [dropOpen, setDropOpen] = useState(false)

    const handleOpenState = (e) => {
        setDropOpen(!dropOpen)
    }

    const handleFocus = (e) => {
        setDropOpen(false)
    }

    return (
        
        <div className={dropStyles.dropWrapper}>
            <div className={dropStyles.dropContainer}>
                <div className={dropStyles.activeItemContainer}>
                    <h3 className={dropStyles.activeItemTitle}>
                        {startingItem}
                    </h3>
                    <button
                        className={dropStyles.dropButton}
                        data-active={dropOpen}
                        onClick={handleOpenState}
                        onBlur={handleFocus}
                    >
                        {dropOpen ? `\u2228` : `>` }    
                    </button>
                </div>
                <div className={dropStyles.dropMenu} data-active={dropOpen}>
                    {dropOpen ?
                        itemList.map((dropItem) => {
                            if (dropItem['itemName'] === startingItem) {
                                return(null)
                            } else {
                                return(
                                    <TitleDropItem
                                        key={dropItem['itemName']}
                                        itemName={dropItem['itemName']}
                                        itemLink={dropItem['itemLink']} 
                                    />
                                )
                            }
                        }) :
                        null
                    }
                </div>
            </div>    
        </div>
    )
}

export default TitleDrop

