import React from "react"
import tinycolor from 'tinycolor2'

import calcStyles from '../hold_calculator.module.scss'
import holdStyles from './display_hold.module.scss'

const DisplayHold = ({ hold, holdVisible }) => {
    
    // colors for bar //
    const worst_color = '#f23078'
    const typical_color = '#f5ef7a'
    const best_color = '#30f2d2'
    const color_multiplier = 1000
    const max_mix = 100

    // func for imperceisely handling float-point rounding hell //
    function truncate (num, places) {
        return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
    }
    
    // convert hold to a display pct //
    var holdDisplay = ''
    var holdAssssment = ''

    if (hold === '--') {
        holdDisplay = '--'
    } else {
        holdDisplay = `${truncate(100*hold, 2)}%`
        if (hold > .07){
            holdAssssment = 'high'
        } else if (hold > .05){
            holdAssssment = 'above average'
        } else if (hold > .040){
            holdAssssment = 'average'
        } else if (hold > 0){
            holdAssssment = 'good'
        } else if (hold > -0.03){
            holdAssssment = 'great'
        } else {
            holdAssssment = 'amazing'
        }
    }
    
    // styles for bar //
    const barScaler = .25 / 0.0476
    var barStyles = {
        'position' : 'absolute',
        'height' : '0px',
        'width' : '0px',
        'zIndex' : 2,
    }

    if (hold === '--') {
        barStyles['top'] = 0
        barStyles['left'] = 0
    } else {
        var holdBarOffset = barScaler * Math.min(.49/barScaler, Math.abs(hold))
        var background = typical_color

        if (hold > 0.0476) {
            background = tinycolor.mix(typical_color, worst_color, Math.min(max_mix,(color_multiplier*1.5)*(Math.abs(hold-0.0476))))
        } else {
            background = tinycolor.mix(typical_color, best_color, Math.min(max_mix,color_multiplier*(Math.abs(hold-0.0476))))
        }

        if (hold === 0) {
            barStyles['height'] = '30px'
            barStyles['width'] = '6px'
            barStyles['top'] = '10px'
            barStyles['left'] = 'calc(50% - 2px)'
            barStyles['backgroundColor'] = background
            barStyles['borderRadius'] = '8px'
        } else if (hold < 0) {
            barStyles['height'] = '30px'
            barStyles['width'] = `${100*holdBarOffset}%`
            barStyles['top'] = '10px'
            barStyles['left'] = `calc(50% - ${100*holdBarOffset}%)`
            barStyles['backgroundColor'] = background
            barStyles['borderRadius'] = '8px 0px 0px 8px'
        } else {
            barStyles['height'] = '30px'
            barStyles['width'] = `${100*holdBarOffset}%`
            barStyles['top'] = '10px'
            barStyles['left'] = `50%`
            barStyles['backgroundColor'] = background
            barStyles['borderRadius'] = '0px 8px 8px 0px'
        }
    }

    return (

        <div>
            <h2 className={calcStyles.title}>
                Vegas Hold
            </h2>
            <h3 className={calcStyles.subtitle}>
                Book's profit margin per $ wagered
            </h3>
            <p className={calcStyles.hold_value} data-active={holdVisible}>
                {holdDisplay} 
            </p>
            <div className={holdStyles.bar_chart_container}>
                <div className={holdStyles.bar_label_container}>
                    <div className={holdStyles.bar_label_even} data-active={holdVisible}>
                        None    
                    </div>
                    <div className={holdStyles.bar_label_typical} data-active={holdVisible}>
                        Typical    
                    </div>
                </div>
                <div className={holdStyles.bar_container}>
                    <div className={holdStyles.bar_spine_top} data-active={holdVisible}></div>
                    <div className={holdStyles.bar_spine_bottom} data-active={holdVisible}></div>
                    <div className={holdStyles.bar_marker_typical} data-active={holdVisible}></div>
                    <div className={holdStyles.bar_marker_even} data-active={holdVisible}></div>
                    <div style={barStyles}></div>
                </div>
                <div className={holdStyles.hold_assessment_container}>
                    {holdVisible ? `This is a ${holdAssssment} hold` : ''}
                </div>
            </div>
        </div>
    
    )

}

export default DisplayHold