import React from "react"
import TitleDrop from "./title_dropdown"



const CalculatorDrop = ({ startingItem }) => {

    const dropList = [
        {
            'itemName' : 'NFL Odds Calculator',
            'itemLink' : '/tools/nfl-odds-calculator/'
        },
        {
            'itemName' : 'NFL Cover Probability',
            'itemLink' : '/tools/nfl-cover-probability-calculator/'
        },
        {
            'itemName' : 'NFL Hold Calculator',
            'itemLink' : '/tools/sportsbook-hold-calculator/'
        },
        {
            'itemName' : 'Hedge Bet Calculator',
            'itemLink' : '/tools/hedge-calculator/'
        },
        {
            'itemName' : 'Parlay Payout Calculator',
            'itemLink' : '/tools/parlay-odds-calculator/'
        }
    ]

    return (
        
        <TitleDrop
            startingItem={startingItem}
            itemList={dropList}
        />
    )
}

export default CalculatorDrop

