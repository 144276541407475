import { Link } from "gatsby"
import React from "react"

import dropStyles from './title_drop.module.scss'

const TitleDropItem = ({ itemName, itemLink }) => {

    // b/c the lost focus on blur will delete the menu before
    // the click can occur, we have to prevent the focus loss
    const preventBlur = (e) => {
        e.preventDefault()
    }
    return (
        
        <div
            role='button'
            tabIndex={0}
            className={dropStyles.dropItemContainer}
            onMouseDown={preventBlur}
        >
            <Link className={dropStyles.dropItemLink}
                to={itemLink}
            >
                <p className={dropStyles.dropItemTitle}>
                    {itemName}
                </p>
            </Link>
        </div>
    )
}

export default TitleDropItem

